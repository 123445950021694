<template>
  <div>
    <div class="edit--account--profile--form">
      <form action="" class="edit--form" v-on:submit.prevent="checkForm">
        <div class="edit--form__pd">
          <div class="edit--topRow">
            <div class="row">
              <div class="col-6">
                <div class="edit--item">
                  <label for="firstname">
                    {{ $t("account.account_first_name") }}
                  </label>
                  <input
                    class="edit--form__input"
                    name="firstname"
                    type="text"
                    v-model="profileFrom.firstName"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="edit--item">
                  <label for="lasttname">
                    {{ $t("account.account_last_name") }}
                  </label>
                  <input
                    class="edit--form__input"
                    name="lasttname"
                    type="text"
                    v-model="profileFrom.lastName"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="edit--item">
            <label for="oldpw">
              {{ $t("account.account_old_password") }}
            </label>
            <input
              class="edit--form__input"
              name="oldpw"
              type="password"
              v-model="profileFrom.oldPassword"
            />
          </div>
          <div class="edit--item">
            <label for="newpw">
              {{ $t("account.account_new_password") }}
            </label>
            <input
              class="edit--form__input"
              name="newpw"
              type="password"
              v-model="profileFrom.newPassword"
            />
          </div>
          <div class="edit--item">
            <label for="cfpw">
              {{ $t("account.account_confirm_new_password") }}
            </label>
            <input
              class="edit--form__input"
              name="cfpw"
              type="password"
              v-model="profileFrom.confirmNewPassword"
            />
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form--input--error" v-if="errors.errorMsg.length">
                <ul>
                  <li v-for="(error, index) in errors.errorMsg" :key="index">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="edit--submit btn--blk">
          {{ $t("account.account_update_button") }}
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { authHeader } from "@/helpers/authHeader";

export default {
  name: "editAccountForm",
  data() {
    return {
      errors: {
        oldPassword: false,
        newPassword: false,
        confirmNewPassword: false,
        errorMsg: []
      },
      profileFrom: {
        firstName: "",
        lastName: "",
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      }
    };
  },
  props: {
    user: Object,
    recordId: Number,
    customFun: Function,
    profileNewCollection: Boolean
  },
  methods: {
    ...mapActions("common", ["setUser", "setToken"]),

    async checkForm(e) {
      this.errors.errorMsg = [];

      if (this.profileFrom.oldPassword == "") {
        this.errors.oldPassword = true;
        this.errors.errorMsg.push(
          this.$t("account.account_old_pw_not_correct")
        );
      } else {
        this.errors.oldPassword = false;
      }

      if (this.profileFrom.newPassword || this.profileFrom.confirmNewPassword) {
        if (
          this.profileFrom.newPassword !== this.profileFrom.confirmNewPassword
        ) {
          this.errors.newPassword = true;
          this.errors.confirmNewPassword = true;
          this.errors.errorMsg.push(
            this.$t("account.account_password_matching")
          );
        } else {
          this.errors.newPassword = false;
          this.errors.confirmNewPassword = false;
        }
      }

      if (
        this.profileFrom.newPassword !== "" &&
        this.profileFrom.confirmNewPassword !== "" &&
        this.errors.newPassword == false &&
        this.errors.confirmNewPassword == false &&
        this.errors.oldPassword == false
      ) {
        let user = await this.apiEditTheProfile(this.profileFrom);
        if (!user) {
          this.errors.oldPassword = true;
          this.errors.errorMsg.push(
            this.$t("account.account_old_pw_not_correct")
          );
          return;
        }
        this.$parent.editAccountProfileClosed();
        this.profileFrom.oldPassword = "";
        this.profileFrom.newPassword = "";
        this.profileFrom.confirmNewPassword = "";
        return;
      }

      if (
        this.errors.oldPassword == false &&
        this.profileFrom.newPassword == "" &&
        this.profileFrom.confirmNewPassword == ""
      ) {
        let user = await this.apiEditTheProfile(this.profileFrom);
        if (!user) {
          this.errors.oldPassword = true;
          this.errors.errorMsg.push(
            this.$t("account.account_old_pw_not_correct")
          );
          return;
        }
        this.$parent.editAccountProfileClosed();
        this.profileFrom.oldPassword = "";
        this.profileFrom.newPassword = "";
        this.profileFrom.confirmNewPassword = "";
      }

      // this.errors.oldPassword = false;
      // this.errors.newPassword = false;
      // this.errors.confirmNewPassword = false;
      // this.errors.titleEn = false;
      // this.errors.titleTc = false;

      // if (!this.collectionForm.title_en) {
      //   this.errors.titleEn = true;
      //   this.errors.errorMsg.push("English name required.");
      // }
      // if (!this.collectionForm.title_tc) {
      //   this.errors.titleTc = true;
      //   this.errors.errorMsg.push("Chinese name required.");
      // }
    },

    async apiEditTheProfile(formData) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/editUserProfile/`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: { "Content-Type": "application/json", ...authHeader() },
            body: JSON.stringify(formData)
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }

        const { user, token } = await response.json();

        if (token) {
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
        }

        // Set the user and token for the global variables
        this.setUser(user);
        this.setToken(token);

        return user;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.close-btn {
  z-index: 100;
  top: -40px;
  right: 0;
}
.edit--account--profile--form {
  height: 100%;
}
.form--wrapper {
  padding: 50px 30px;

  h2 {
    font-size: 18px;
    font-weight: 200;
    margin-bottom: 34px;
  }
  label {
    font-size: 18px;
  }
  input[type="text"] {
    display: block;
    width: 90%;
    padding-bottom: 5px;
    margin: 8px 0;
    box-sizing: border-box;
    font-size: 22px;
    border-bottom: 1px solid #555;
    outline: none;
    &::placeholder {
      // color:
      font-size: 18px;
      @include respond-to(screen-md) {
        font-size: 22px;
      }
    }
    &.error {
      border-bottom-color: red;
    }
  }
}
.form--input {
  &--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    @include respond-to(screen-md) {
      margin-bottom: 20px;
    }
    label {
      width: 10%;
    }

    .private--collection {
      position: relative;
      margin-top: 20px;
      .c-cb-wrap {
        // opacity: .4;
        line-height: 1;
        width: 100%;
        display: block;
        position: relative;
        padding-left: 20px;
        margin-bottom: 0px;
        cursor: pointer;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;
        & input {
          opacity: 0.4;
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked ~ .checkmark {
            background-color: #e6e6e6;
            border: 1px solid black;
          }
          &:checked ~ .checkmark:after {
            background-color: #e6e6e6;
            border: 1px solid black;
            // opacity: 0;
          }
          &:checked {
            opacity: 1;
          }
        }
      }
      .checkmark {
        top: 50%;
        /* margin: auto; */
        transform: translateY(-50%);
        position: absolute;
        // top: 0;
        left: 0;
        height: 13px;
        width: 13px;
        background-color: rgb(0, 0, 0);
        border: 1px solid black;
        border-radius: 50%;
        &:after {
          content: "";
          position: absolute;
          display: none;
          // position: absolute;
          // left: 9px;
          // top: 5px;
          // width: 5px;
          // height: 10px;
          // border: solid white;
          // border-width: 0 3px 3px 0;
          // -webkit-transform: rotate(45deg);
          // -ms-transform: rotate(45deg);
          // transform: rotate(45deg);
        }
      }
    }
  }
  &--error {
    margin-bottom: 20px;
    ul {
      color: red;
      margin: 0;
      padding: 0;
    }
  }
}
.c-cb {
  justify-content: left;
}
.edit--submit {
  width: 100%;
  text-transform: uppercase;
}

.edit {
  &--form {
    @include respond-to(screen-md) {
      width: 590px;
    }
    &__input {
      display: block;
      width: 100%;
      border-bottom: 1px solid $color-black;
      padding: 10px 0 5px;
      font-size: 22px;
      margin-bottom: 40px;
    }
    &__pd {
      padding: 50px;
    }
  }
  &--item {
    label {
      font-size: 18px;
    }
  }
  &--submit {
    width: 100%;
  }
  .close-btn {
    z-index: 100;
    top: -40px;
    right: 0;
  }
}
</style>
